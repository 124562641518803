import { navigate, PageProps } from 'gatsby';
import { UserData } from 'packages/innedit';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import { ReducersProps } from '~/reducers';

const IndexPage: FC<PageProps> = ({ location }) => {
  const {
    cms: { userIsLoading, userIsLogged, user },
  } = useSelector((globalState: ReducersProps) => globalState);

  useEffect(() => {
    if (!userIsLoading && userIsLogged && user) {
      const espaceId =
        user.espaceIds && user.espaceIds.length > 0 && user.espaceIds[0];
      if (espaceId) {
        navigate(`/espaces/${espaceId}/dashboard`);
      } else {
        navigate(`/profile`);
      }
    }
  }, [userIsLoading, userIsLogged, user?.id]);

  useEffect(() => {
    try {
      UserData.signInWithEmailLink(location.href);
    } catch (e: unknown) {
      toast.error((e as Error).message);
    }
  }, [location.href]);

  return (
    <div className="flex min-h-main flex-col items-center justify-center md:p-24">
      <div className="flex space-x-3">
        <Button color="neutral" to="/login" variant="outline">
          Se connecter
        </Button>
      </div>
    </div>
  );
};

export default IndexPage;
